$(document).ready(()=> {

    // Disable a[href="#"] tags jump
    $('a[href="#"]').click(function(e){
        e.preventDefault();
    });

    $('[data-toggle="tooltip"]').tooltip()


    //========== slider ==============//
    var intro_slider = new Swiper('.information__wrapper__item__slider .swiper-container', {
        slidesPerView: 1,
        spaceBetween: 35,
        loop: 1,
        speed: 300,
        // autoplay: {
        //     delay: 3000,
        //     disableOnInteraction: 0
        // },
        pagination: {
            el: '.swiper-pagination',
            // clickable: true,
    
        },
    });

    var image_slider = new Swiper('.information__wrapper__item--image .swiper-container', {
        slidesPerView: 1,
        spaceBetween: 35,
        effect:"fade",
        fadeEffect: {
            crossFade: true
          },
        loop: 1,
        speed: 300,
        // autoplay: {
        //     delay: 3000,
        //     disableOnInteraction: 0
        // },
     

    });
    

    intro_slider.controller.control = image_slider;
    image_slider.controller.control = intro_slider;
    
    // $(document).on('mouseover','.information__wrapper__item__slider',function(){
    //     intro_slider.autoplay.stop();
    // })
    // $(document).on('mouseleave','.information__wrapper__item__slider',function(){
    //     intro_slider.autoplay.start();
    // })

    //==============end slider===============//

   

    $('body').on('mousewheel DOMMouseScroll', function(e){
        if(typeof e.originalEvent.detail == 'number' && e.originalEvent.detail !== 0) {
          if(e.originalEvent.detail > 0) {
            intro_slider.slideNext();

          } else if(e.originalEvent.detail < 0){
            intro_slider.slidePrev();
    

          }
        } else if (typeof e.originalEvent.wheelDelta == 'number') {
        
          if(e.originalEvent.wheelDelta < 0) {
            intro_slider.slideNext();
     

          } else if(e.originalEvent.wheelDelta > 0) {
            intro_slider.slidePrev();
            

          }
        }
      });
   



});


